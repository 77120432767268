export const BG_PRIMARY = 'rgb(60 60 60)';
export const BG_PRIMARY_GRADIENT = 'rgb(60 60 60 / 50%)';
export const BG_PRIMARY_DARK = 'rgb(35 35 35)';
export const BG_PRIMARY_DARK_GRADIENT = 'rgb(35 35 35 / 80%)';

export const BG_SECONDARY = '#F6F0E7';
export const BG_SECONDARY_GRADIENT = 'rgb(237 237 237 / 10%)';
export const BG_ALTERNATIVE = 'rgb(117 76 51)';
export const BG_ALTERNATIVE_DARK = 'rgb(35 35 35)';
// color text
export const TEXT_PRIMARY = '#fff';
export const TEXT_PRIMARY_LIGHT = '#F5F6E8';
export const TEXT_SECONDARY = '#5B341E';
export const TEXT_ALTERNATIVE = '#aa7b29';
export const TEXT_SHADOW = '#3c1410';
// music & navigation
export const NAVIGATION_COLOR = '#aeaeae';
export const DRAWER_COLOR = 'rgb(116 84 63)';
export const BLACK_COLOR = '#362A22';
// Frame Color
export const RSVP_BORDER_COLOR = '#aa7b29'; // Akad, Resepsi, RSVP, Gift
export const WEDDING_BORDER_COLOR = '#FFF'; // Welcome, Wishes

// DEFAULT BUTTON PROPS
export const BUTTON_GRADIENT = `linear-gradient(to right, rgb(35 35 35 / 0.43), rgb(174 174 174 / 0.43) 200%)`;
export const BUTTON_PROPS = {
  _active: { borderColor: 'transparent' },
  _hover: { borderColor: 'transparent' },
  _focus: { borderColor: 'transparent' },
};
